import Marionette from 'backbone.marionette';
import Radio from 'backbone.radio';
import deburr from 'lodash.deburr';
import ScopeValidator from '../editor/scope-validator';
import translator from '../translator';

/** Normalizes a book name or a potential scope for matching. */
function prepareScope(name) {
    return deburr(name).toLocaleLowerCase().replace(/\s/, '');
}

/**
 * A view which manages the scope/search input on most Bible pages.
 */
export default Marionette.View.extend({

    // The el, '#bible-search', should be set by the layout view.

    ui: {
        input: 'input'
    },

    template: false,

    events: {
        'input @ui.input': 'validate',
        'submit':          'validate'
    },

    initialize: function (options) {
        options.edition.fetchToc().then((toc) => {

            const books = toc.getBooks();

            // A mapped of lowercased, deburred book abbreviations and names,
            // used for exact matches.
            this.namesToBooks = new Map();

            for (const book of books) {
                this.namesToBooks.set(
                    prepareScope(book.getAbbreviation()), book
                );
                this.namesToBooks.set(prepareScope(book.getName()), book);
                for (const alternative of book.getAlternativeNames()) {
                    this.namesToBooks.set(prepareScope(alternative), book);
                }
            }

            // A list of all possible book names and abbreviations, for
            // partial matches.
            this.possibleNames = Array.from(this.namesToBooks.keys());

            this.scopeValidator = new ScopeValidator(
                options.edition.getLang(),
                toc.getBookAbbreviations(),
                'chapter'
            );
        });

        Radio.channel('page-scope')
            .on('change', scope => this.getUI('input').val(scope));
    },

    validate: function () {
        const text = this.getUI('input').val().trim();
        let message = null;

        if (text.length > 2) {

            // First see if it might be a book and chapter.
            let scopeText = prepareScope(text);
            const match = /^(\d?\s*[a-z]+)\s*\d/.exec(scopeText);

            if (match) {

                // We have at least a potential book and chapter.
                // Check the book.
                const possibleName = prepareScope(match[1]);
                let book;

                if (this.namesToBooks.has(possibleName)) {
                    // Look first for an exact match.
                    book = this.namesToBooks.get(possibleName);
                } else {
                    // Then look for any name which starts with the same
                    // letters.
                    book = this.possibleNames
                        .find(name => name.startsWith(possibleName));
                    if (book) {
                        book = this.namesToBooks.get(book);
                    }
                }

                if (book) {
                    // Replace whatever we matched with expected abbreviation.
                    scopeText = scopeText.replace(
                        match[1], book.getAbbreviation()
                    );

                    if (this.scopeValidator.validate(scopeText)) {

                        // For display in the tooltip, use the full book name
                        // and add a space after the name.
                        message = translator.translate(
                            'bible.passage.goto',
                            scopeText.replace(
                                book.getAbbreviation(), `${book.getName()} `
                            )
                        );

                        this.getUI('input').attr('name', 'scope');
                        this.el.action = '/bible';
                    }
                }
            } else {
                message = translator.translate('bible.passage.search', text);
                this.getUI('input').attr('name', 'text');
                this.el.action = '/search';
            }
        }

        if (message) {
            this.getUI('input').removeClass('invalid');
            if (this.$('.tooltip').length == 0) {
                this.$el.append('<div class="tooltip"></div>');
            }
            this.$('.tooltip').text(message);
        } else {
            if (text.length > 0) {
                this.getUI('input').addClass('invalid');
            } else {
                this.getUI('input').removeClass('invalid');
            }
            this.$('.tooltip').remove();
        }

        return message != null;
    }
});
