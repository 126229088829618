/**
 * @fileOverview A controller for the Bible navigation menus.
 */
import $ from 'jquery';
import { chain, once, pick, result } from 'underscore';
import { Events } from 'backbone';
import Editions from '../../collections/editions';
import NavBooksTemplate from './books.handlebars';
import NavChaptersTemplate from './chapters.handlebars';
import NavPericopesTemplate from './pericopes.handlebars';

/**
 * @class
 *
 * @param config Configuration options.
 *
 * @param {String} config.edition: The edition of the Bible to navigate.
 *
 * @param {String} [config.terminate_at="pericope"] How deep into the Bible
 *     to navigate: "book", "chapter", or "pericope".
 *
 * @param {String} [config.target] An URL template for creating links at
 *     terminal depth.
 */
const BibleNavigation = function (config) {
    config = pick(config, 'edition', 'terminate_at', 'target');
    this.edition = config.edition;
    this.terminate_at = result(config, 'terminate_at', 'pericope');
    this.target = result(config, 'target', '');
    Object.assign(this, Events);
};

/**
 * Attach the object to the page.
 */
BibleNavigation.prototype.attach = function () {

    const self = this;

    $('.bible-browse-link').attr('href', '').on('click', function (event) {

        // Make sure that if a user clicks on a link which should
        // display a sub-navigation menu, an existing menu stays in
        // place for the moment
        event.stopPropagation();
        event.preventDefault();
        self.hide('#bible-toc-nav');
        self.render($(this).attr('data-scope'));
    });

    $(document).click(() => {
        self.hide('#bible-toc-nav');
    });
};

/**
 * Get a Promise for the table of contents for the edition for navigation.
 *
 * @return {Promise} A Promise for a TableOfContents object.
 */
BibleNavigation.prototype.fetchToc = once(function () {

    const edition = this.edition;
    const editions = new Editions();

    return Promise.resolve(editions.fetch()).then(() => {
        return editions.get(edition).fetchToc();
    });
});

/**
 * Render a new Bible navigation panel.
 *
 * @param {String} scope The scope which the navigation panel should
 *     display: "at", "ot", or "nt" for the Old or New Testament,
 *     a book abbreviation for the chapters in a book, or a book and
 *     chapter number for the pericopes in a chapter.
 */
BibleNavigation.prototype.render = function (scope) {

    const self = this;

    this.fetchToc().then((toc) => {

        let result, book;

        if (scope == 'at' || scope == 'ot' || scope == 'nt') {
            self.show(self.getBooksPanel(toc, scope), 'book');
        } else if (scope.search(/[a-z]$/i) !== -1) {
            book = toc.findBook(scope);
            if (book.getChapters().length === 0) {
                Promise.resolve(book.fetch()).then(() => {
                    self.render(book.getAbbreviation());
                });
                return;
            }
            self.show(self.getChaptersPanel(book), 'chapter');
        } else {
            result = scope.match(/^(.*[a-z])(\d+)$/i);
            if (result) {
                book = toc.findBook(result[1]);
                self.show(self.getPericopesPanel(book, result[2]), 'pericope');
            }
        }
    });
};

/**
 * Get a panel for navigating books in part of the Bible.
 *
 * @param {TableOfContents} toc The model for the table of contents.
 *
 * @param {String} scope "at", "ot", or "nt" for the Old or New Testament.
 */
BibleNavigation.prototype.getBooksPanel = function (toc, scope) {

    let column = [];
    const part = toc.getPart(scope);
    const max_list_count = part.getBooks().length / 3 * 1.3;
    let i = 0;

    const data = {
        columns: [ column ]
    };

    part.getGroupings().forEach((grouping) => {
        i += grouping.length;
        if (i > max_list_count) {
            column = [];
            data.columns.push(column);
            i = grouping.length;
        }
        grouping.forEach((abbr) => {
            let book = toc.findBook(abbr);
            column.push({
                name: book.getName(),
                abbr: book.getAbbreviation()
            });
        });

    });

    return $(NavBooksTemplate(data));
};

/**
 * Get a panel for navigating chapters in a book.
 *
 * @param {Book} book The model for the book.
 */
BibleNavigation.prototype.getChaptersPanel = function (book) {

    let chapter;
    let column = [];

    const data = {
        book: {
            name: book.getName(),
            abbr: book.getAbbreviation()
        },
        columns: [ column ]
    };

    // Try to create reasonably balanced columns
    const chapters = book.getChapters();
    let chapters_per_col, n;

    if (chapters.length >= 100) {
        // Only Psalms, 15 chapters per column
        chapters_per_col = 15;
    } else if (chapters.length >= 20) {
        // Default value
        chapters_per_col = 10;
        // But if the number of chapters is evenly divisible by a number
        // between 7 and 12, use that number instead.
        for (n = 12; n > 6; n--) {
            if (chapters.length % n === 0) {
                chapters_per_col = n;
                break;
            }
        }
    } else if (chapters.length >= 7) {
        // Default value
        chapters_per_col = Math.floor(chapters.length / 2);
        // But if the number of chapters is evenly divisible by a number
        // between 5 and 9, use that number instead.
        for (n = 9; n > 4; n--) {
            if (chapters.length % n === 0) {
                chapters_per_col = n;
                break;
            }
        }
    } else {
        chapters_per_col = chapters.length;
    }

    for (n = 1; n <= chapters.length; n++) {
        chapter = chapters[n - 1].chapter;
        column.push(chapter);
        // New column if needed
        if (n < chapters.length && n % chapters_per_col === 0) {
            column = [];
            data.columns.push(column);
        }
    }

    return $(NavChaptersTemplate(data));
};

/**
 * Get a panel for navigating pericopes in a chapter.
 *
 * @param {Book} book The model for the book.
 *
 * @param {Number} chapter The chapter number within the book.
 */
BibleNavigation.prototype.getPericopesPanel = function (book, chapter) {

    const data = {
        book: {
            name: book.getName(),
            abbr: book.getAbbreviation()
        },
        chapter: chapter
    };

    const target = this.target;

    // Pattern to match book abbreviation, space, and a chapter number
    // to select the proper pericopes for the chapter and to filter out
    // the book and chapter from displayed pericope scopes
    const pattern = new RegExp(data.book.abbr + ' ' + chapter + '\\D');

    // Display a pericope for the whole chapter and any other pericopes
    // defined within the chapter
    data.pericopes = chain(book.getChapters())
     .filter(book => book.chapter == chapter)
     .map((book) => {
        return chain(book)
                .pick(book, 'scope')
                .defaults({ title: '' })
                .value();
      })
     .union(book.getPericopes().filter((pericope) => {
         return pericope.scope.abbr.search(pattern) != -1;
      }))
    .map((pericope) => {
        return {
            title: pericope.title,
            scope: {
                relative:  pericope.scope.abbr.replace(pattern, ''),
                url_param: pericope.scope.url_param
            },
            href: target.replace('{scope}', pericope.scope.url_param)
        };
    })
    .value();

    return $(NavPericopesTemplate(data));
};

/**
 * Add a new bible navigation menu to the window and slide it into place.
 *
 * @param {HTMLElement} panel The new navigation menu.
 *
 * @param {String} level The navigation level for the panel: 'book',
 *     'chapter', or 'pericope'.
 */
BibleNavigation.prototype.show = function (panel, level) {

    const self = this;

    $(panel).on('click', 'a', {level: level}, function (event) {

        // Use the level specified in the link or the panel level
        const level = $(this).data('level') || event.data.level;

        // This will also dispose of the panel
        self.hide($(panel));

        if (self.terminate_at != level) {
            self.render($(this).data('scope'));
        }

        self.trigger(
            'navigate:' + level,
            self.target.replace('{scope}', $(this).data('scope')),
            $(this).attr('data-scope')
        );
    });

    // Make sure that if a user clicks elsewhere within the panel, it stays
    // in place.
    $(panel).on('click', event => event.stopPropagation());

    // The new element will slide into place, so add the element far off
    // screen so that it does not briefly flash into its original
    // position before then sliding into place, and then place it in the
    // starting position for the slide. The element must be added before
    // the proper place to position it for the beginning of the slide can
    // be determined.

    $(panel)
        .css('top', -5000)
        .appendTo('#header .row2')
        .css('top', -10 - $(panel).outerHeight())
        .animate({ top: 0 });
};

/**
 * Slide a bible navigation menu out of view and remove it from the document.
 *
 * @param {HTMLElement} panel The navigation menu to hide.
 */
BibleNavigation.prototype.hide = function (panel) {
    $(panel).animate(
        { top: -10 - $(panel).outerHeight() },
        { complete: function () {
            $(this).remove();
        }}
    );
};

export default BibleNavigation;
