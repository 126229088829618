import $ from 'jquery';
import Marionette from 'backbone.marionette';
import translator from '../translator';
import SessionTimeoutDialog from './templates/dialog-session-timeout.handlebars';

// The number of milliseconds to display the dialog before logging out
// the user.
const WARNING_TIMESPAN = 120000;

// The number of milliseconds between polls
const POLL_RATE = 10000;

/**
 * A view for managing the user session, including expiration and renewal.
 *
 * Shortly before the session expires, a dialog will be displayed allowing
 * the user to renew the session.
 */
export default Marionette.View.extend({

    template: SessionTimeoutDialog,

    /**
     * Initialize the view.
     *
     * @param {Object} options - Marionette.View options, including common
     *     options such as user used in views across the application.
     *
     * @param {User} options.user - A user model, which becomes the model
     *     for the view.
     */
    initialize: function (options) {

        this.model = options.user;

        // Check periodically to see if we are near or past the expiration
        this.interval = window.setInterval(this.check.bind(this), POLL_RATE);
    },

    templateContext: function () {
        return { translator: translator };
    },

    /**
     * Display the rendered HTML as a dialog.
     */
    attachElContent: function (html) {

        const keepalive = this.model.keepalive.bind(this.model);

        this.setElement($(html).dialog({
            resizable: false,
            height:    'auto',
            width:     400,
            modal:     true,
            buttons:   [{
                text:  translator.translate('continue'),
                click: function () {
                    keepalive();
                    $(this).dialog('destroy');
                }
            }]
        }));

        this.listenToOnce(this.model, 'logout', () => {
            window.location.reload();
        });

        return this;
    },

    /**
     * Periodically check to see if the user's login will soon expire or
     * has expired.
     */
    check: function () {

        let exp = this.model.get('exp');

        if (exp) {

            exp = exp * 1000;
            const nextPoll = Date.now() + POLL_RATE;

            if (nextPoll > exp) {
                this.model.logout();
            } else if (nextPoll + WARNING_TIMESPAN > exp) {
                if (! this.$el.dialog('instance')) {
                    this.render();
                }
            }
        }
    }
});
