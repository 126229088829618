import $ from 'jquery';

/**
 * Return a function which can return the JSON data attached to the
 * 'body' element.
 *
 * @return {Object}
 */
export default function () {
    return $('body').data('config');
}
