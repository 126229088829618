/**
 * A Handlebars helper which translates messages.
 *
 * It relies on the current context or the root context having property named
 * 'translator' with a Translator instance.
 */
export default function () {
    const options = arguments[arguments.length - 1];
    const translator = this.translator || options.data.root.translator;
    if (typeof translator == "undefined") {
        return arguments[0];
    }
    const args = Array.prototype.slice.call(arguments, 0, arguments.length - 1);
    return translator.translate.apply(translator, args);
}
