import Marionette from 'backbone.marionette';
import translator from '../translator';
import config from '../config';
import { apiOperations } from '../models/api-operations';
import LayoutView from '../views/layout';
import Edition from '../models/edition';
import User from '../models/user';
import { pickCommonOptions } from '../util/common-options';
import getMessageCatalogue from '../get-message-catalogue';
import './common-domready';

/**
 * An object which can be used as is as the starting point for a page or
 * which can serve as the basis for other objects.
 *
 * The standard Marionette.Application startup sequence is to create
 * an application and then call start(). This application adds additional
 * methods for asynchronous preparation. When the Promise returned by
 * prepare() resolves, start() can be called.
 *
 * Common initialization for all pages in the site is performed when this
 * module is loaded.
 *
 * The object initializes three properties:
 *
 * - `edition` - An object representing the edition to display.
 * - `lang` - A two-letter language code representing the language of the page.
 * - `user` - A user model.
 *
 * @class Application
 */
export default Marionette.Application.extend({

    edition: null,

    lang: document.documentElement.lang,

    user: null,

    /** Initial asynchronous setup tasks. */
    preparationTasks: {
        translator: { task: prepareTranslator, then: [] },
        api:        { task: prepareApi,        then: [ prepareUser ] },
    },

    initialize: function () {

        if (config().edition) {

            this.edition = new Edition({
                id:       config().edition,
                fallback: config().fallback
            });

            this.preparationTasks.api.then.push(prepareEdition);
        }

        Marionette.Application.prototype.initialize.apply(this, arguments);
    },

    /**
     * Perform asynchronous setup tasks.
     *
     * @return {Promise} - A Promise for this application.
     */
    prepare: function () {

        const ready = this.ready.bind(this);

        return Promise.all(
            Object.values(this.preparationTasks).map((primaryTask) => {
                return primaryTask.task.call(this, this).then(() => {
                    return Promise.all(primaryTask.then.map((subtask) => {
                        return subtask.call(this, this);
                    }));
                });
            })
        ).then(ready);
    },

    /**
     * A callback called after all asynchronous setup tasks have finished.
     *
     * @return {Object} - This object.
     */
    ready: function () {
        this.layoutView = new LayoutView(pickCommonOptions(this));
        return this;
    }
});

/**
 * Create a translator and load messages.
 *
 * @param {Application} app - The app from which to get the language and on
 *     which to set the translator.
 *
 * @return {Promise} - A Promise for the app fulfilled when the messages have
 *     been loaded.
 */
async function prepareTranslator(app) {
    translator.init(await getMessageCatalogue(app.lang));
    return app;
}

/**
 * Fetch API operations.
 *
 * @param {BibleApplication} app - The app from which to get the API
 *     operations.
 *
 * @return {Promise} - A Promise for the app fulfilled when the API operations
 *     have been fetched.
 */
function prepareApi(app) {
    apiOperations.url = config().api_url;
    return apiOperations.fetch().then(() => { return app; });
}

/**
 * Load the user from storage if possible and refresh credentials.
 */
function prepareUser(app) {

    app.user = User.createFromStorage();

    if (! app.user.isNew()) {

        // Since we have loaded a user from storage, refresh the
        // credentials before the application starts
        return Promise.resolve(app.user.keepalive());
    }

    // No user
    return Promise.resolve(app.user);
}

/**
 * Fetch information about the current edition.
 *
 * @param {BibleApplication} app - The app from which to get the edition
 *     object.
 *
 * @return {Promise} - A Promise for the app fulfilled when the edition
 *     information has been fetched.
 */
function prepareEdition(app) {
    return Promise.resolve(app.edition.fetch()).then(() => app);
}
