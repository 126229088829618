import { pick } from 'underscore';
import Backbone from 'backbone';
import VirtualCollection from 'backbone-virtual-collection';

/*
 * A grouping of notes of the same type with a title.
 *
 * The rubric may also contain the notes for the rubric.
 *
 * Model attributes:
 *
 * - title: The rubric title.
 * - abbr: The rubric abbreviation.
 * - order: The sort order of this rubric relative to other rubrics.
 * - notes: If the rubric contains notes, a VirtualCollection of notes whose
 *       type matches the rubric ID.
 */
const Rubric = Backbone.Model.extend({

    parse: function (response) {
        return pick(response, 'id', 'title', 'abbr', 'order');
    },

    /**
     * Set the notes contained in the rubric.
     *
     * Only notes whose 'type' attribute matches this rubric's ID will be
     * added to the rubric.
     *
     * @param {Notes} notes - A Backbone.Collection of Notes.
     *
     * @return {this}
     */
    setNotes: function (notes) {

        const rubricNotes = new VirtualCollection(notes, {
            filter: { type: this.id }
        });

        // Changing a note's type will trigger an 'add' or 'remove' event but
        // will not trigger an 'update' on the virtual collection. Trigger it
        // here.
        //
        // This is somewhat of a hack for an edge case. This should only
        // occur if the type of a draft note changes.
        //
        // For reference,
        //
        //     - 'add' or 'remove' events on the underlying collection may
        //       trigger 'add' or 'remove' events on the virtual collection
        //     - the subsequent 'update' event on the underlying collection
        //       will be likewise triggered on the virtual collection.
        //     - a 'change' event on a model may trigger a 'change', 'add',
        //       or 'remove' on the collection, but not an update.
        //
        // VirtualCollection:_onChange() adds added models to _changeCache but
        // does not add removed models. Handle adds and removes differently.

        rubricNotes.on({
            add: (model, collection, options) => {
                if (model.hasChanged('type')) {
                    // Access VirtualContainer internals to trigger 'update',
                    // which is not ideal
                    collection._onUpdate(collection, options);
                }
            },
            remove: (model, collection, options) => {
                if (model.hasChanged('type')) {
                    options = Object.assign({}, options, {
                        changes: { added: [], merged: [], removed: [ model ] }
                    });
                    collection.trigger('update', collection, options);
                }
            }
        });

        this.set('notes', rubricNotes);

        return this;
    },

    /**
     * Determine whether the rubric contains any notes.
     *
     * @return {Boolean}
     */
    isEmpty: function () {
        const notes = this.get('notes');
        return notes ? notes.size() === 0 : true;
    }
});

export default Rubric;
