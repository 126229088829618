import Backbone from 'backbone';
import { apiOperations } from './api-operations';
import Register from './register';
import Rubric from './rubric';
import Rubrics from '../collections/rubrics';
import RubricTree from '../collections/rubric-tree';

/**
 * Trees of registers and rubrics for parts (testaments) of an edition.
 *
 * Model attributes:
 *
 * - rubrics: All rubrics in the edition.
 * - ot: Registers and rubrics for the Old Testament.
 * - nt: Registers and rubrics for the New Testament.
 * - marg_ref_type: A rubric for marginal references which are not
 *       properly part of a rubric tree.
 */
const Trees = Backbone.Model.extend({

    /**
     * Initialize the model after creation.
     *
     * @param {Object} [attributes] - Backbone.Model attributes.
     *
     * @param {Object} [options] - Backbone.Model options.
     *
     * @param {Edition} [options.edition] - The edition for which to obtain
     *     rubrics.
     */
    initialize: function (attributes, options) {
        options = options || {};
        this.edition = options.edition;
    },

    url: function () {
        return apiOperations.render(
            'edition-rubrics', { edition: this.edition.id }
        );
    },

    getRubrics: function () {
        return this.get('rubrics');
    },

    getRubricTree: function (part) {
        return this.get(part);
    },

    parse: function (response, options) {

        options = { ...options, ...{ parse: true } };

        const rubrics = new Rubrics(response.rubrics, options);

        const registersOt = response.registers.map((register) => {
            return new Register(register, options);
        });

        const registersNt = response.registers.map((register) => {
            return new Register(register, options);
        });

        const ot = new RubricTree();
        const nt = new RubricTree();

        response.rubrics.forEach((response) => {

            const rubric = rubrics.get(response.id);

            // Add rubrics with registers to the appropriate register.
            // Add rubrics without registers directly to the groups.

            if (response.register_ot) {
                const index = response.register_ot.split('/').pop();
                registersOt[index].addRubric(rubric);
            } else {
                ot.add(rubric);
            }

            if (response.register_nt) {
                const index = response.register_nt.split('/').pop();
                registersNt[index].addRubric(rubric);
            } else {
                nt.add(rubric);
            }
        });

        // Add registers to groups after rubrics without registers.

        ot.add(registersOt);
        nt.add(registersNt);

        return {
            rubrics:      rubrics,
            ot:            ot,
            nt:            nt,
            marg_ref_type: new Rubric(response.marg_refs, options)
        };
    }
});

export default Trees;
