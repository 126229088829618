/**
 * @fileOverview Startup code for all pages on the site, run when the
 * DOM is ready.
 */
import $ from 'jquery';
import featureDetect from './init/feature-detect';
import initializeBibleNavigation from './init/bible-navigation';
import initializeLanguageChange from './init/lang-change';
import initializeUserMenus from './init/user-menus';
import './init/backbone-debug';

$(() => {
    featureDetect();
    initializeUserMenus();
    initializeLanguageChange();
    initializeBibleNavigation();
});
