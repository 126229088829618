import $ from 'jquery';
import ScopeMatcher from '../scope/matcher';

/**
 * Creates a scope validator.
 *
 * @param {string} lang - The two-letter code of the language in which to match
 *     scopes, used to determine which separators to use.
 * @param {string[]} abbrs - Possible book abbreviations.
 * @param {'book'|'chapter'|'verse'} precision - The precision to which a
 *     string must match.
 */
function ScopeValidator(lang, abbrs, precision = 'verse') {
    this.subscopePattern = ScopeMatcher.pattern(lang, abbrs);
    this.separator = lang == 'fr' ? '\\s*;?' : ';?';
    this.precision =
        (precision == 'book' || precision == 'chapter' ? precision : 'verse');
    if (precision == 'book') {
        this.abbrs = abbrs;
    }
}

/**
 * Associates the scope validator with an element, adding event handlers.
 *
 * @param {string|jQuery|Element} selector - A jQuery selector which finds
 *     the element.
 */
ScopeValidator.prototype.attach = function (selector) {
    this.selector = selector;
    $(this.selector).on('keyup', this.onKeyup.bind(this));
};

/**
 * Event handler for keyup events.
 *
 * Add or remove a jQuery UI error class based on whether or not the element
 * value validates.
 */
ScopeValidator.prototype.onKeyup = function () {
    if (this.validate()) {
        $(this.selector).removeClass('invalid');
    } else {
        $(this.selector).addClass('invalid');
    }
};

/**
 * Detach all event handlers from the element.
 */
ScopeValidator.prototype.detach = function () {
    $(this.selector).off();
};

/**
 * Validate a string or the contents of the element.
 *
 * @param {String} [scope] The string to match. If not set, the contents of
 *     of the element will be matched directly.
 * @return {Boolean}
 */
ScopeValidator.prototype.validate = function (scope = null) {

    scope = scope || $(this.selector).val();

    // Shortcut for string containing only a book
    if (this.precision == 'book' && this.abbrs.includes(scope)) {
        return true;
    }

    let matches = true;
    let pos = 0;
    let book = null;
    let chapter = null;

    // Set the regular expression first to match at the start of the string.
    let re = new RegExp(`^${this.subscopePattern}`);

    // Return true if there is a match, it matches the whole value, and
    // the match includes only book(s), chapter(s), and/or verse(s)

    while (pos < scope.length && matches) {

        const match = re.exec(scope.substring(pos));
        matches = (match !== null);

        if (matches) {
            book = match[1] || book;
            chapter = match[3] || chapter;
            pos = pos + match[0].length;

            if (this.precision == 'book' || this.precision == 'chapter') {
                // If we only have a chapter, it will end up in match[4].
                matches = book && (chapter || match[4]);
            } else {
                matches = book && chapter && match[4];
            }

            // From now on, make sure a separator precedes the pattern.
            re = new RegExp(`${this.separator}${this.subscopePattern}`);
        }
    }

    return matches;
};

export default ScopeValidator;
