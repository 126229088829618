import Backbone from "backbone";
import config from "../config";
import Edition from "../models/edition";

/**
 * A collection of editions of the Bible.
 */
export default Backbone.Collection.extend({

    model: Edition,

    url: function () {
        return config().editions_url;
    }
});
