var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li><span class=\"scope\"><a"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"href") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":69}}})) != null ? stack1 : "")
    + " data-scope=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"scope") : depth0)) != null ? lookupProperty(stack1,"url_param") : stack1), depth0))
    + "\">"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"scope") : depth0)) != null ? lookupProperty(stack1,"relative") : stack1), depth0))
    + "</a></span>\n      <span class=\"title\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":26},"end":{"line":6,"column":37}}}) : helper)))
    + "</span></li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"href") || (depth0 != null ? lookupProperty(depth0,"href") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"href","hash":{},"data":data,"loc":{"start":{"line":5,"column":51},"end":{"line":5,"column":61}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"bible-toc-nav\" class=\"pericopes\">\n  <div class=\"title\"><a data-level=\"book\" data-scope=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"book") : depth0)) != null ? lookupProperty(stack1,"abbr") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"book") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</a> "
    + alias2(((helper = (helper = lookupProperty(helpers,"chapter") || (depth0 != null ? lookupProperty(depth0,"chapter") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"chapter","hash":{},"data":data,"loc":{"start":{"line":2,"column":91},"end":{"line":2,"column":104}}}) : helper)))
    + "</div>\n  <ul class=\"pericope list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"pericopes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "  </ul>\n</div>\n";
},"useData":true});