import { vsprintf } from 'sprintf-js';

/**
 * A simple translation utility which stores message catalogues as sets of
 * keys and values and can format messages using sprintf()-style syntax.
 *
 * The translator must be initialized with a set of translation keys and
 * messages.
 */
class Translator {

    /**
     * Translation keys and messages.
     * @var {Object.<string, string>} messages
     */
    messages = {};

    /**
     * Sets the translation keys and messages for the translator.
     *
     * @param {Object.<string, string>} messages
     */
    init(messages) {
        this.messages = messages;
    }

    /**
     * Translate a message into the current language.
     *
     * @param {string} message
     * @returns string
     */
    translate(message, ...args) {
        return vsprintf(this.messages[message], args);
    }
}

/**
 * A global translator instance.
 *
 * The translator must be initialized before use.
 */
const translator = new Translator();
export default translator;
