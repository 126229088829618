import Backbone from 'backbone';

/**
 * A replacement for Backbone.sync which returns a Promise for a jQuery jqXHR
 * rather than returning the jqXHR directly.
 *
 * @return {Promise} - A Promise for a jQuery jqXHR.
 */
export default function () {
    return Promise.resolve(Backbone.sync.apply(this, arguments));
}
