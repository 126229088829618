import Backbone from 'backbone';
import Rubric from '../models/rubric';

/**
 * A collection of rubrics.
 */
export default Backbone.Collection.extend({

    model: Rubric,

    parse: function (response) {

        response = response.rubrics || response;

        // Inject an order field into each rubric
        let order = 0;

        return response.map((rubric) => {
            order++;
            return { ...rubric, ...{ order: order } };
        });
    }
});
