import $ from 'jquery';

function closeMenus() {
    $('#header .menu-button ul').removeClass('open');
}

/**
 * Initialize user menus.
 */
export default function () {

    // Close any open menus and open this menu.
    $('#header .menu-button').on('click', function () {
        closeMenus();
        $('ul', this).addClass('open');
        $('.tooltip', this).removeClass('show');
    }).on('mouseenter', function () {
        $('.tooltip', this).addClass('show');
    }).on('mouseleave', function () {
        $('.tooltip', this).removeClass('show');
    });

    // Close any open menus when a click occurs outside a menu button.
    $(document).on('click', (event) => {
        if ($(event.target).parents('.menu-button').length == 0) {
            closeMenus();
        }
    });

    // TODO: opening a Bible navigation menu will not close user menus.
}
