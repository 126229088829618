/**
 * Loads translation message catalogues for a language.
 *
 * This module exists so that the Translator object itself does not need
 * to concern itself with how to load message catalogues.
 *
 * @module
 */
import { isString } from 'underscore';

/** Message catalogues already loaded. */
const catalogues = {};

/**
 * Gets a message catalogue for use with a given language.
 *
 * @param {string} lang A two-letter ISO-636 language code.
 */
export default async function getMessageCatalogue(lang) {
    if (! catalogues[lang]) {
        const messages = await import(`languages/messages.${lang}`);
        catalogues[lang] = flatten(messages);
    }
    return catalogues[lang];
}

/**
 * Flattens hierarchical keys.
 *
 * For example,
 *
 *     {
 *         key1: "message1",
 *         key2: { key3: "message3", key4: "message4" }
 *     }
 *
 * becomes
 *
 *     {
 *         key1: "message1",
 *         "key2.key3": "message3",
 *         "key2.key4": "message4"
 *     }
 */
function flatten(src, path) {

    let dst = {};

    for (let key in src) {

        const value = src[key];

        if (path) {
            key = path + '.' + key;
        }

        if (isString(value)) {
            dst[key] = value;
        } else {
            Object.assign(dst, flatten(value, key));
        }
    }

    return dst;
}
