/**
 * @fileOverview Provides a class and a regular expression pattern which can
 * match scopes.
 */

/**
 * A class when can recognize if a string can be parsed as a scope without
 * actually performing full scope parsing.
 *
 * @param {String} lang - The language for which to match references to
 *     biblical passages.
 *
 * @param {String[]} bookAbbrs - A list of book abbreviations.
 *
 * @class
 */
const Matcher = function (lang, bookAbbrs) {
    this.lang = lang;
    this.bookAbbrs = bookAbbrs;
    this.re = new RegExp(Matcher.pattern(lang, bookAbbrs));
};

/**
 * Scan a string to determine whether or not it contains textual cross
 * references.
 *
 * @param {String} text - The string to scan.
 *
 * @return {array|null} - An array of results from RegExp.exec().
 */
Matcher.prototype.scan = function (text) {
    return this.re.exec(text);
};

/**
 * Return a regular expression pattern for matching scopes.
 *
 * @param {String} lang - The language for which to match references to
 *     biblical passages.
 *
 * @param {String[]} bookAbbrs - A list of book abbreviations.
 *
 * @return {String}
 */
Matcher.pattern = function (lang, bookAbbrs) {

    // Components which make up the final regular expression:
    //
    // versePattern: A regular expression pattern which matches a verse
    //     number followed optionally by a verse part or 's' or 'ss'
    //     (French and Spanish) or 'f' or 'ff' (English).
    // delimiterPattern: A regular expression pattern which matches
    //     only a chapter separator ',' (French and Spanish) or ':'
    //     (English); a range separator '-'; or a separator for
    //     discontiguous verses in the same chapter '.' (French) or ','
    //     (English). The pattern does not match verses in separate
    //     chapters.
    //
    // The final regular expression is made up of the following
    // components:
    //
    // book abbreviation followed by space (optional)
    // chapter number followed by a delimiter (optional)
    // verse number
    // a series of delimiters followed by additional verse numbers
    //     (optional)
    //
    // The pattern must be followed by a non-digit character or
    // the end of a line.
    //
    // Three elements are populated in the resulting array of matches:
    //    0: The entire string matched.
    //    1: The book abbreviation, if found.
    //    2: The chapter and verses.
    //    3: The chapter.
    //    4: The verses.

    let versePattern, delimiterPattern, pattern;

    switch (lang) {
        case 'fr':
        case 'es':
            versePattern = '\\d+(?:ss|s|a?b?c?d?e?f?)';
            // \u2013 EN DASH
            delimiterPattern = '[-\\u2013,\\.]';
            pattern = '(?:(' + bookAbbrs.join('|') + ')\\s*)?((?:(\\d+)\\,)?'
                    + '(' + versePattern
                    + '(?:' + delimiterPattern + versePattern + ')*))'
                    + '(?=\\D|$)';
            break;
        default: // 'en'
            versePattern = '\\d+(?:ff|f|a?b?c?d?e?)';
            // \u2013 EN DASH
            delimiterPattern = '[-\\u2013:,]';
            pattern = '(?:(' + bookAbbrs.join('|') + ')\\s*)?((?:(\\d+):)?'
                    + '(' + versePattern
                    + '(?:' + delimiterPattern + versePattern + ')*))'
                    + '(?=\\D|$)';
            break;
    }

    return pattern;
};

export default Matcher;
