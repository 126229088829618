import { pick } from 'underscore';

/**
 * A list of common options. The following keys are contained in the list:
 *
 * - `edition` - An object representing the edition to display.
 * - `mediaAuthorization` - An object for obtaining authorization tokens for
 *   the media platform.
 * - `router` - A Backbone.Router
 * - `user` - A user model.
 */
export const commonOptions = [
    'edition', 'mediaAuthorization', 'router', 'user'
];

/**
 * Given an object containing options, filter the options retaining only
 * common options.
 *
 * @param {Object} options
 *
 * @return {Object} A new object containing only common options.
 */
export function pickCommonOptions(options) {
    return pick(options, commonOptions);
}

/**
 * Return an object containing common options extended by additional options.
 *
 * @param {Object} options
 *
 * @param {Object} otherOptions
 *
 * @return {Object} A new object containing common options as well as all of
 *     the options specified in `otherOptions`. Options in `otherOptions`
 *     will override options with the same key in `options`.
 */
export function extendCommonOptions(options, otherOptions) {
    return { ...pickCommonOptions(options), ...otherOptions };
}

/**
 * Set common options directly on an object.
 *
 * This function works like Marionette.mergeOptions() except that the list
 * of options is fixed.
 *
 * The function does not contain a binding for `this` and therefore must
 * be added to the properties of an existing object or invoked using
 * `.call()`.
 *
 * @return {Object} This object.
 */
export function mergeCommonOptions(options) {
    return Object.assign(this, pickCommonOptions(options));
}
