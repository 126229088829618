import $ from 'jquery';

/**
 * Language change form initialization and logic.
 */
export default function () {

    // Menu functionality already established in user-menus.js

    $('#lang-select li').on('click', function () {
        const lang = this.dataset.lang;
        if (document.documentElement.lang != lang) {
            let url = `${$('#lang-select')[0].dataset.url}?lang=${lang}`;
            const scope = $('#lang-select').attr('data-scope');
            if (scope.length > 0) {
                url = `${url}&scope=${scope}`;
            }
            window.location = url;
        }
    });
}
