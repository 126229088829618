import BibleNavigation from '../../views/bible-navigation';
import Config from '../../config';

/**
 * Initialize Bible navigation.
 */
export default function () {

    const config = Config();
    let bibleNavigation;

    if (config.bible_navigation && config.bible_navigation.target &&
        config.edition) {

        config.bible_navigation.edition = config.edition;
        bibleNavigation = new BibleNavigation(config.bible_navigation);
        bibleNavigation.attach();
    }
}
