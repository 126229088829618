/**
 * @fileOverview An entry point for pages which need only default application
 * startup.
 */
import $ from 'jquery';
import Application from './application';

$(() => {
    const app = new Application();
    app.prepare().then((app) => { app.start(); });
});
